export default [
  {
    title: "销售订单",
    path: "/channelOrder/orderList",
    active: true
  },
  {
    title: "分销直采订单",
    path: "/channelOrder/distributorPurchase",
    active: false
  },
  {
    title: "销售进度查询",
    path: "/channelOrder/salesProgress",
    active: false
  }
];
