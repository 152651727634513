import {
    getList,
    batch,
    //    驳回
    orderReject,
    cancleReject,
    exportOrder, //导出
    salesOrderPrint,
    changeTransFlag, cancleOrder
} from "./api";
import navList from '../navList'
import Util from "@/utils/utils";
import sortBar from './components/SortBar'
import chooseProduct from './components/chooseProduct/cancelReason.vue'
export default {
    name: 'scaleOrderList',
    components: {
        sortBar,
        chooseProduct
    },
    data() {
        return {
            printTitleChangeFlag:false,
            printTitle:'',
            breadcrumbData: [{
                    path: "/index",
                    name: "index",
                    title: "首页",
                },
                {
                    path: "/channelOrder/orderList",
                    name: "orderList",
                    title: "销售订单（审核出库）",
                },
            ],
            navList: [],
            page: {
                pageNo: 1,
                pageSize: 10,
                sortName: "so.document_date",
                sortOrder: "desc"
            },
            goodsList: [],
            invalidList: [], //批量失效
            insearchOBJ: [],
            paramList: [],
            searchzz: false,
            loadings: false,
            // 暂无数据
            NoDate: false,
            nolistImg: require("@/assets/order/noList.png"),
            // 暂无更能多数据
            noMore: false,
            // 查看更多
            loadMores: true,
            goodsNmuber: 0,
            pageLoadFlag: true,
            requestdata: {},
            isLoading: false,
            printData:{
                Master:{},
                Details:[],
                countTotal:'',
                amount:'',
              },//打印接口数据
              isDataOk:false,//打印接口数据是否返回
              visibleshop:false,
              openType:'',
              salesOrderId:'',
              saleItem:[],
              mesageInfoTips:'',
            sortList: [
                {
                    name: "创建时间",
                    sortName:'so.document_date',
                    sortStatus: 1 //0默认 1降 2升序
                },
                {
                    name: "审核时间",
                    sortName:'so.acknowledge_date',
                    sortStatus: 0 //0默认 1降 2升序
                },
                {
                    name: "出库时间",
                    sortName:'so.inventory_date',
                    sortStatus: 0 //0默认 1降 2升序
                }
            ],
            filterFrom:{
                endDate: '',
                documentType: '',
                documentNum: '',
                sellerName: '',
                returnStatus: '',
                purchaseNum: '',
                customerName: '',
                startDate: '',
                status: '',
            },
            modelShowSign:false,
            item:{},
            toastMaskVisible: false,
            documentId: null,
        }
    },
    beforeCreate() {
        // 添加背景色
        document.querySelector('body').setAttribute('style', 'background-color:#F7F7F7')

    },
    beforeDestroy() {
        let that = this;
        window.removeEventListener("storage", that.storageWatch);
        document.querySelector('body').setAttribute('style', '')
    },
    mounted() {
        let that = this;
        that.navList = navList
        //根据筛选条件二次请求 不需要初始化调用
        // that.getCommodityList()
        this.getMessage()

        window.addEventListener('storage', that.storageWatch)
    },
    methods: {
        handleOkClick(){
            let routeUrl = this.$router.resolve({
                path: "/channelOrder/hisenseo2oOutbound",
                query: {
                    id: this.documentId
                }
            });
            window.open(routeUrl.href, "_blank");
            this.toastMaskVisible = false;
        },
        toProDetail(proCode, orgId) {
            let routeUrl = this.$router.resolve({
                path: '/product/detail',
                query: {
                    productCode: proCode,
                    orgId: orgId
                }
            });
            window.open(routeUrl.href, "_blank");
        },
        // 去支付
        handleToPay(item){
            if(item.feeList && item.feeList.length > 0) {
                let feeCode = item.feeList[0]
                this.$router.push({
                    path: "/serviceFeeModule/payDetail",
                    query: {
                        id:feeCode,
                        batch:0,
                        b2bOrderCode: item.documentNum,
                        orgName: '订单号',
                        channel: 2
                    }
                });
            } else {
                this.$message.warning('暂无待支付订单')
            }
        },
        getReason(e){
            this.$router.push({
                path: "/installationRepairReporting/workOrderSubmission",
                query: {
                    item: JSON.stringify(this.item),
                    proItem: JSON.stringify(e),
                    type:'2',
                }
            })
        },
        gotoBuy(item,type) {
            let proList = item.salesOrderItem.length>0? item.salesOrderItem.filter(it=>(it.repairFlag =='Y' || it.installFlag =='Y')):[]
            if(proList.length==0){
                this.$message.warning('暂无可报装报修数据')
                return
            }
            if(proList.length > 1 ) {
                this.item = item
                this.productList = proList
                this.modelShowSign = true
                return
            }
            if(proList.length > 0 ){
                this.$router.push({
                    path: "/installationRepairReporting/workOrderSubmission",
                    query: {
                        item: JSON.stringify(item),
                        proItem: JSON.stringify(proList),
                        type:type,
                    }
                })
            }

        },
        storageWatch(e) {
            // 监听到销售订单状态变化刷新列表
            if (e.key === 'saleOrderListTime') {
                if(e.newValue > e.oldValue || (e.newValue && !e.oldValue)){
                    //刷新
                    this.getCommodityList();
                }
            }
          },
        // 快速出库
        gotoPage(num) {
            let routeUrl = this.$router.resolve({
                path: num==0?"/channelOrder/quickAudit":"/channelOrder/quickDelivery",
                query:{

                }
            });
            window.open(routeUrl.href, "_blank");
        },
        //筛选排序
        sortClick(data) {
            const { sortStatus } = data;
            this.filterFrom = {
                ...this.filterFrom,
                pageNo: 1
            };
            this.page.sortOrder = sortStatus == 0 ? "" : sortStatus == 2 ? "asc" : "desc"
            this.page.sortName = data.sortName;
            this.page.pageSize = 10;
            this.getCommodityList();
        },

    // 获取配置信息
    getMessage() {
        const msg =  this.$configTotal({
          route: this.$route.fullPath,
          id:'14187495683'
        })
        this.mesageInfoTips = msg.msgDesc
    },
    printTitleChangeOk(){
        this.$nextTick(()=>{
            this.printTitleChangeFlag = false;
            this.isDataOk = true;
        })
    },
        //打印按钮点击
    printBtnClickFun(row){
        this.printTitle = '';//清空标题
        //数据接口请求完毕
        // isDataOk
        this.isDataOk = false;
        salesOrderPrint({orderId:row.id}).then(res => {
            this.printTitleChangeFlag = true;
          let resData = JSON.parse(res.data);
          if(resData.code == 0){
            this.printData.Master = resData.Master[0];
            this.printData.Details = resData.Details;
            this.printData.countTotal = 0;
            this.printData.amount = 0;
            for (const item of resData.Details) {
              this.printData.countTotal += (Number(item.backnowledgedQty) || 0);
              this.printData.amount += (Number(item.acknowledgedAmount)) || 0;
            }
            this.printTitle = this.printData.Master.printTitle;

            this.$nextTick(()=>{
            //   this.isDataOk = true;
            })
          }
        }).catch(err=>{
            this.$message.warning('单据获取失败，请重试')
        });
      },
        orderCancle(salesOrderId) {
            this.pageLoadFlag = true
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                salesOrderId
            }
            cancleReject(data).then(res => {
                var realData = JSON.parse(res.data);
                if (realData.code == '1') {
                    this.$message.warning(realData.msg);
                    this.pageLoadFlag = false
                } else {
                    this.$message.success('取消成功');
                    this.page.pageSize = 10;
                    this.pageLoadFlag = false
                    this.getCommodityList()
                }
            }).catch(err=> {
                this.pageLoadFlag = false
            })
        },

        openWindows(salesOrderId,saleItem,type) {
            this.openType = type
            this.visibleshop = true
            this.salesOrderId = salesOrderId
            this.saleItem = saleItem
        },
        cancelOk() {
            if(this.openType == 'cancelBo') {
                this.orderReturn()
            }
            if(this.openType == 'cancelShen') {
                this.orderCancle( this.salesOrderId)
            }
            if(this.openType == 'cancelOrder' ){
                this.orderCancle2( this.salesOrderId)
            }
        },
        orderCancle2(salesOrderId) {
            this.pageLoadFlag = true
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                orderIds:salesOrderId
            }
            cancleOrder(data).then(res => {
                var realData = JSON.parse(res.data);
                if (realData.code == '1') {
                    this.$message.warning(realData.msg);
                    this.pageLoadFlag = false
                } else {
                    this.$message.success('取消成功');
                    this.page.pageSize = 10;
                    this.pageLoadFlag = false
                    this.getCommodityList()
                }
            }).catch(err=> {
                this.pageLoadFlag = false
            })
        },
        // 审核驳回
        orderReturn() {
            this.pageLoadFlag = true
            let salesOrderId = this.salesOrderId
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                salesOrderId,
                userAccount: this.$store.state.user.userInfo.account.account,
            }
            orderReject(data).then(res => {
                var realData = JSON.parse(res.data);
                if (realData.code == '0') {
                    this.pageLoadFlag = false
                    this.$message.success('驳回成功');
                    if(this.saleItem.userActivityCode) {
                        let userActivityCode = this.saleItem.userActivityCode
                        let productIds = []
                        let dmsOrderCode = this.saleItem.purchaseNum
                        let qtys = []
            
                        this.saleItem.salesOrderItem.forEach(element => {
                            productIds.push(element.productCode) 
                            qtys.push(-(element.backnowledgedQty))
                        });
                        let changeDta = {
                            userActivityCode:userActivityCode,
                            productIds:productIds.join(','),
                            dmsOrderCode:dmsOrderCode,
                            qtys:qtys.join(',')
                        }
                        changeTransFlag(changeDta).then(res=>{
                        }) 
                          
                    }
                    this.page.pageSize = 10;
                    this.getCommodityList();
                    
                } else {
                    this.$message.warning(res.data.msg);
                    this.pageLoadFlag = false
                }
            }).catch(error => {
                this.pageLoadFlag = false
            })
           
        },
        //表单筛选
        inputSearch(data) {
            this.paramList = data[1].paramList;
            this.page.pageNo = 1;
            this.searchzz = true;
            this.pageLoadFlag = true;
            this.page.pageSize = 10;
            this.getCommodityList();
        },
        // 全选
        chooseAll(e) {
            if (e.target.checked) {
                for (let i = 0; i < this.goodsList.length; i++) {
                    this.goodsList[i].checked = true;
                }
            } else {
                for (let i = 0; i < this.goodsList.length; i++) {
                    this.goodsList[i].checked = false;
                }
            }
            this.$forceUpdate();
        },
        //批量失效
        invalidClick() {
            let newarraySend = this.invalidList.join(',')
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                userAccount: this.$store.state.user.userInfo.customer.userAccount,
                salesOrderIds: newarraySend
            }
            batch(data).then(res => {
                var realData = JSON.parse(res.data);
                if (realData.code == '1') {
                    this.$message.warning(realData.msg);
                } else if (realData.code == '0') {
                    this.$message.success(realData.msg)
                }
            })
        },
        // 导出
        exportClick() {
            let data = JSON.parse(JSON.stringify(this.requestdata));
            data.page.pageSize = 300000;
            data.page.pageNo = 1;
            this.isLoading = true;
            exportOrder(data).then(res => {
                this.isLoading = false;
                Util.blobToJson(res.data).then(content => {
                    if (content && content.msg == 'success') {
                        this.$message.success('下载成功，请到下载中心查看!')
                    }
                }).catch(err => {
                    Util.downloadFile(res.data, '销售订单.xls')
                }).finally(() => {
                    this.expLoading = false;
                })
            })
        },

        // 商品选择
        chooseItem(data, index, e) {
            this.goodsList[index].checked = e.target.checked;
            var pushY = this.invalidList.indexOf(data);
            if (pushY == -1) {
                this.invalidList.push(data);
            } else {
                this.invalidList.splice(pushY, 1);
            }
            this.$forceUpdate()
        },
        // 点击审核
        shenhe(id) {
            let routeUrl = this.$router.resolve({
                path: '/channelOrder/orderExamine',
                query: {
                    id
                }
            });
            window.open(routeUrl.href, "_blank");
        },
        // 去采购
        gotoCG(type, id,item){
            // let path = '/prototyZone/detailXS?id='+id
            // let routeUrl = this.$router.resolve({
            //     path: path,
            // });
            // window.open(routeUrl.href, "_blank");
            // return

            let productInfoQueryList = []
                item.salesOrderItem.forEach(item =>{
                productInfoQueryList.push({
                    productInfoId:item.productCode,
                    quantity:item.backnowledgedQty
                })
            })
            this.$router.push({
                path: "/prototyZone/sumbitOrder",
                query:{
                    remark:item.message,
                    orgId: item.orgId,
                    orgName: item.orgName,
                    shopCisCode:item.yjStoreCode?item.yjStoreCode:"",
                    type: 'caigou', //
                    operateFlag: 1,//需要传过去判断展示哪个提交按钮
                    materialGroupCode:item.salesOrderItem[0].materialGroupCode,
                    agentAndSupplier: item.orgName, //
                    productInfoQueryList:JSON.stringify(productInfoQueryList),
                    dealerCode:item.dealerCode,//分销的code
                    orderNumber:  item.customerCode + '_'+item.purchaseNum, //再来一单接口需要的orderCode
                }
            });
        },
        //查看订单详情
        toDetail(type, id,item) {
            console.log(item.documentType);
            // 如果是分销采购订单 跳转
            if(item.documentType == 'normal'){
                let routeUrl = this.$router.resolve({
                    path: '/retailOrder/detailFX',
                    query: {
                        id,
                        name:'销售订单列表'
                    }
                });
                window.open(routeUrl.href, "_blank");
                return;
            }
            // 如果是零售订单 跳转
            if(item.documentType == 'retail'){
                let routeUrl = this.$router.resolve({
                    path: '/retailOrder/detailRetail',
                    query: {
                        id,
                        name:'销售订单列表'
                    }
                });
                window.open(routeUrl.href, "_blank");
                return;
            }
            if( item.isProtoType) {
                let path = '/prototyZone/detailXS?id='+id
                let routeUrl = this.$router.resolve({
                    path: path,
                    type:'detail'
                });
                window.open(routeUrl.href, "_blank");
                return
            }
            let routeUrl = this.$router.resolve({
                path: '/retailOrder/detail',
                query: {
                    id,
                    name:'销售订单列表'
                }
            });
            window.open(routeUrl.href, "_blank");
        },
        // 查看订单进度
        toProgress(type, id) {
            let routeUrl = this.$router.resolve({
                path: '/retailOrder/progress',
                query: {
                    id,
                    name:'销售订单列表'
                }
            });
            window.open(routeUrl.href, "_blank");
        },
        // 点击出库
        chuku(id,item) {
            // 信小蜜的跳转
            if (item.orderLabels.indexOf('信小蜜') !==  -1 ) {
                let routeUrl = this.$router.resolve({
                    path: "/channelOrder/xinxiaomiOutbound",
                    query: {
                        id
                    }
                });
                window.open(routeUrl.href, "_blank");
            }else if(item.dataSource == "O2O"){
                this.toastMaskVisible = true;
                this.documentId = item.id;
            }else if (item.orderLabels.indexOf('用户录入') !==  -1 ) {
                let routeUrl = this.$router.resolve({
                    path: "/channelOrder/saleOrderOut",
                    query: {
                        id
                    }
                });
                window.open(routeUrl.href, "_blank");
            } else {
                let routeUrl = this.$router.resolve({
                    path: '/channelOrder/orderExamine',
                    query: {
                        id,
                        type:'CK'
                    }
                });
                window.open(routeUrl.href, "_blank");
            }
        },
        // 商品列表
        getCommodityList() {
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                terms: {
                    ...this.filterFrom,
                    paramList: this.paramList
                },
                page: this.page
            }
            this.requestdata = data;
            this.pageLoadFlag = true;
            getList(data).then(res => {
                this.pageLoadFlag = false;
                var realDate = JSON.parse(res.data);
                this.goodsNmuber = realDate.page.totalCount;
                this.loadings = false;
                if (this.searchzz) {
                    this.goodsList = realDate.data;
                }
                if (realDate.data.length == 0 && this.page.pageNo != 1) {
                    this.noMore = true;
                    this.loadMores = false;
                    //没有更多数据
                } else if (realDate.data.length != 0 && this.page.pageNo != 1) {
                    this.goodsList = realDate.data;
                } else if (realDate.data.length != 0 && this.page.pageNo == 1) {
                    this.goodsList = realDate.data;
                    document.title = '信天翁' + '-' + this.goodsList[0].documentTypeName;
                    this.NoDate = false;
                    this.noMore = false;
                    this.loadMores = true;

                } else if (realDate.data.length == 0 && this.page.pageNo == 1) {
                    this.NoDate = true;
                    this.noMore = false;
                    this.loadMores = false;
                }
                if (realDate.data && realDate.data.length != 0 && this.page.pageNo * this.page.pageSize >= realDate.page.totalCount) {
                    this.noMore = true;
                    this.loadMores = false;
                }
                for (let i = 0; i < this.goodsList.length; i++) {
                    this.goodsList[i].checked = false;
                }
                this.goodsList.forEach((item) => {
                    item.dataArr = []
                    item.orderLabelsList = []
                    if(item.orderLabels) {
                        item.orderLabelsList = item.orderLabels.split(',')
                    }
                    if(item.discountTypeName == '组合购') {
                      item.dataArr=[]
                      item.dataArr.push(this.changeData(item.salesOrderItem))
                     
                      this.$forceUpdate()
                    }
                  });
                
            })
        },
          //组合购数据更改
          changeData(items) {
            var map = {},
                dest = [];
            for (var i = 0; i < items.length; i++) {
                var ai = items[i];
                if (!map[ai.productGroup]) {
                    dest.push({
                        productGroup: ai.productGroup,
                        name: ai.productGroupRemark,
                        num: ai.packageRateNum,
                        data: [ai]
                    });
                    map[ai.productGroup] = ai;
                } else {
                    for (var j = 0; j < dest.length; j++) {
                        var dj = dest[j];
                        if (dj.productGroup == ai.productGroup) {
                            dj.data.push(ai);
                            break;
                        }
                    }
                }
            }
            return dest;
        },
        // 加载更多
        loadMore() {
            this.loadings = true;
            this.page.pageNo = 1;
            this.page.pageSize += 10
            this.getCommodityList();
        }
    }
}