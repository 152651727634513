<template>
  <a-modal 
    v-model="visible" 
    @ok="handleOk" 
    @cancel="cancle" 
    @afterClose='cancle'
    wrapClassName="cancelName" 
    :width='473'
    :closable="false"
    centered
    :maskClosable='false'
    :ok-button-props="{ props: { disabled: submitBtn } }"
    >
    <div class="modal_close" @click="cancle">
        <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>选择型号
      </span>
    </div>
    <div class="cancelContent">
      <a-form :form="form" ref="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 12 }">
        <a-form-item label="选择型号">
          <a-select
            mode="multiple"
            v-model="productCode"
            style="width: 300px;height:32px;" 
            placeholder="请选择"
            @change="changeInput"

            >
            <a-select-option
              :value="item.zzprdmodel"
              v-for="(item,index) in productList"
              :key="index"
            >
              {{item.zzprdmodel}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </div>
    <div class="cancelInfo">
    
    </div>
  </a-modal>
</template>
<script>
export default {
  
  name:'choose-Product',
  data(){
    return{
      productCode:[],
      chooseItem: []
     }
  },
  props:{
    productList:{
      type: Array,
      default: () => [],
    },
    visible:{
      type:Boolean,
      default:false
    },
  },
  watch:{
    productList: {
      handler: function(val, oldVal) {
      },
      immediate: true
    },
    visible: {
      handler: function(val, oldVal) {
        if(!val){
          this.productList = []
        }
      },
      immediate: true
    },
  },
  mounted(){

  },
  methods:{
    changeInput(){
      // 修改为多选
      // this.chooseItem = this.productList.filter(it => it.zzprdmodel == this.productCode )
      this.chooseItem =  this.productList.filter(element => this.productCode.includes(element.zzprdmodel))
    },
    handleOk(){
      if(!this.productCode) {
        this.$message.warning('请选择型号')
        return
      }
      this.$emit('update:visible',false)
      this.$emit('cancelReasonHandOk',this.chooseItem)

    },
    cancle(){
      this.$emit('update:visible',false)
      this.chooseObj = {}
      this.productList = []
    },


    
  }
}
</script>
<style lang="less" scoped>
  /deep/ .cancelName{
    .ant-modal-content{
      text-align:center;
      border-radius: 10px;
      .ant-modal-body{
        padding:30px 0px 0 45px;
        .cancelContent{
          text-align: left;
          padding:0 40px 0 0;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          .ant-tree-node-selected {
            background-color: red;
          }
          .ant-form {
            line-height: 32px;
          }
          .ant-form-item-control-wrapper{
            margin-left:16px;
          }
          .ant-form-item-control{
            line-height: 32px;
          }
          .ant-form-item-label{
            line-height: 32px;
          }
          .ant-form-item{
            margin-bottom:0;
          }
          .messageInfo{
            font-size: 12px;
            font-weight: 400;
            color: #AAAAAA;
            line-height: 12px;
            margin-top:8px;
            margin-left:86px;
          }
        }
        .cancelInfo{
          padding:24px 0 50px 0px;
          text-align: left;
          overflow: hidden;
          .cancleZi{
            width: 78px;
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            line-height: 20px;
            float:left;
            padding-left:11px;
            margin-right:9px;
          }
          .ant-input{
            width: 400px;
            height:88px;
            overflow: auto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
          .ant-input-affix-wrapper{
            width: 400px;
          }
        }
        .modal_close {
          position: absolute;
          top: 0;
          right: 0;
          width: 42px;
          height: 42px;
          line-height: 42px;
          border-radius: 0 10px 0 0;
          text-align: center;
          cursor: pointer;
          .modal_close_icon {
            color: #aaa;
            font-size: 16px;
          }
        }
        .modal_close:hover {
          background: #E4F8F7;
          .modal_close_icon {
            color: #08ABA8;
          }
        }
        .modal_title_box {
          text-align: center;
          padding-bottom:50px;
          .modal_title {
            position: relative;
            color: #262626;
            text-align: center;
            z-index: 10;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing:1px;
            .modal_title_line {
              display: inline-block;
              width: 100%;
              position: absolute;
              top: 13px;
              left: 0;
              border-top: 10px solid #E4F8F7;
              z-index: -10;
            }
          }
        }
      }
      p{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #262626;
        line-height: 30px;
      }
      .ant-modal-footer{
        text-align: center;
        border-top:0px solid #fff;
        padding:0px 0 30px;
        .ant-btn{
          width: 120px;
          height:40px;
          border-radius: 4px;
          font-size: 16px;
          color:#777;
        }
        .ant-btn-primary{
          color: #fff;
          background:#00AAA6;
          margin-left:24px;
        }
      }
    }
  }
</style>


