<!--
 * @Author: sqk
 * @Date: 2020-09-02 10:28:52
 * @LastEditTime: 2020-09-28 21:04:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\filtrate\Filtrate.vue
-->

<template>
  <div class="sort-bar-box clearfix">
    <ul>
      <li
        @click="sortFun(item, index)"
        v-for="(item, index) in data"
        :key="index"
        class="d-f-c"
        :class="
          item.sortStatus == 0 ? '' : item.sortStatus == 1 ? 'sort' : 'resort'
        "
      >
        <span>{{ item.name }}</span>
        <i class="iconfont icon-shangsheng"></i>
      </li>
    </ul>
    <div  class="total-box">共搜索到 {{total}} 条数据</div>
    <div class="freeText">
      <p class="text">{{cartInfoTip}}</p>
    </div>

    <div class="edit">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "SortBar",
  data() {
    return {
      currentProvide:undefined,
      searchValue:'',
      cartInfoTip:''
    };
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    total: {
      type: [String, Number],
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    showTotal: {
      type: Boolean,
      default: false
    },
    provides: {
      //供应商列表
      type: Array,
      default: function (){
        return []
      }
    },
    searchWidth: {
      type: Number,
      default: 270
    },

  },
  mounted() {
    this.getMessage()
  },
  methods: {
    getMessage() {
      const msg =  this.$configTotal({
        route: this.$route.fullPath,
        id:'14187495683'
      })
      this.cartInfoTip = msg.msgDesc
    },
    sortFun(item, idx) {
      //需要emit
      for (let key in this.data) {
        if (key == idx) {
          continue;
        } else {
          this.data[key].sortStatus = 0;
        }
      }
      if (item.sortStatus == 0) {
        item.sortStatus = 1;
      } else if (item.sortStatus == 1) {
        item.sortStatus = 2;
      } else {
        item.sortStatus = 0;
      }

      this.$emit('click',item);

    },
  }
};
</script>

<style lang="less" scoped>
.sort-bar-box {
  width: 1188px;
  height: 54px;
  padding-top: 5px;
  margin: 0 auto;
  background-color: #fff;
  margin-top: 16px;
  margin-bottom: 16px;
  .iconfont{
    font-size: 16px;
    color: #aaa;
  }
  // padding-left: 10px;
  ul {
    float: left;
    padding-right: 24px;
    margin-left: 8px;
    li {
      height: 44px;
      line-height: 44px;
      text-align: center;
      //padding: 0 6px;
      margin-left: 16px;
      float: left;
      cursor: pointer;
      transition: all 0.1s;
      color: #262626;
      &.sort {
        color: #00aaa6;
        .iconfont {
          visibility: visible;
          transform: rotate(180deg);
          color: #00aaa6;
        }
      }
      &.resort {
        color: #00aaa6;
        .iconfont {
          visibility: visible;
          color: #00aaa6;
        }
      }
      &:hover {
        color: #00aaa6;
        .iconfont {
          color: #00aaa6;
        }
      }
      span {
        display: inline;
      }
      .iconfont {
        font-size: 8px;
        margin-left: 6px;
        color: #777;
        visibility: hidden;
      }
    }
  }
  .total-box {
    font-size: 14px;
    color: #777777;
    height: 18px;
    line-height: 18px;
    border-left: 1px solid #eee;
    text-indent: 23px;
    float: left;
    margin-top: 14px;
    margin-right: 16px;
  }
  .freeText {
    //background: #ffffff;
    //box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.13);
    border-radius: 2px;
    float: left;
    //margin-left: 350px;
    margin-top:7px;
    .text {
      padding: 6px 12px 6px 12px;
      font-size: 12px;
      font-weight: 400;
      color: #ff8f3c;
    }
  }
  .input-search {
    float: right;
    padding: 6px 24px 6px 0;
    text-align: left;
    .icon-shouye-sousuo:hover{
      color: #00aaa6;
    }
  }
  .go-box {
    float: right;
    padding: 6px 10px 6px 0;
    .title {
      color: #262626;
      font-size: 16px;
    }

    .label {
      color: #262626;
      font-size: 14px;
      margin-left: 60px;
    }

    .sel-wrap {
      width: 300px;
      margin-left: 16px;
      margin-right: 40px;
    }
  }
}

.edit{
  float: right;
}
</style>
